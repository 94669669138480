var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['tabs', {'showTab': _vm.showTab, 'isVn': _vm.isVn}]},[_c('div',{class:['item', { active: _vm.active == 'Schedule' }],on:{"click":function($event){return _vm.$router.push({ name: 'Schedule' })}}},[(_vm.active == 'Schedule')?_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Schedule-active.svg`),"alt":""}}):_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Schedule${
          _vm.theme == 'dark' ? '-active' : ''
        }.svg`),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("home.Schedule"))+" ")]),_c('div',{class:['item', { active: _vm.active == 'Integral' }],on:{"click":function($event){return _vm.$router.push({ name: 'Integral' })}}},[(_vm.active == 'Integral')?_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Integral-active.svg`),"alt":""}}):_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Integral${
          _vm.theme == 'dark' ? '-active' : ''
        }.svg`),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("home.Integral"))+" ")]),_c('div',{class:['item', { active: _vm.active == 'Player' }],on:{"click":function($event){return _vm.$router.push({ name: 'Player' })}}},[(_vm.active == 'Player')?_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Player-active.svg`),"alt":""}}):_c('img',{attrs:{"src":require(`@/assets/imgs/comm/Player${
          _vm.theme == 'dark' ? '-active' : ''
        }.svg`),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("home.Player"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }