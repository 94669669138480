<template>
  <div class="Home" @scroll="scrollHandle($event)">
    <Title :lucency="lucency"/>
    <Header :headerHeight="headerHeight"/>

    <div ref="viewContent" :class="['viewContent', { overflow: overflow }]" :style="{'margin-top': headerHeight-21+'px'}">
      <router-view></router-view>
    </div>
    <Tabs :showTab="showTab"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Tabs from "@/components/Tabs";
import { mapState } from "vuex";
import Title from "@/components/Header/Title";
export default {
  name: "Home",
  data() {
    return {
      headerHeight: 200,
      scrollTime: null,
      lucency: 0,
      showTab: true,
      showTabTime: null
    };
  },
  components: {
    Header,
    Tabs,
    Title
  },
  created(){
    this.headerHeight = document.body.clientWidth * (200/375)
  },
  computed: {
    overflow: {
      get() {
        return this.common.overflow;
      },
      set(val) {
        this.$store.commit("common/SET_DATA", {
          name: "overflow",
          data: val
        });
      }
    },
    ...mapState(["common"])
  },
  methods: {
    scrollHandle(e) {
      this.showTab = false;
      if(this.showTabTime){
        clearTimeout(this.showTabTime)
      }
      this.showTabTime = setTimeout(() => {
        this.showTab = true;
      }, 600)


      if (this.scrollTime) return;
      this.scrollTime = setTimeout(() => {
        
        if(e.srcElement.scrollTop > 100){
          this.lucency = 1-(144 - e.srcElement.scrollTop) / 33 
        }else {
          this.lucency = 0;
        }
        if (e.srcElement.scrollTop < 144) {
          this.overflow = false;
        } else {
          this.lucency = 1;
          this.overflow = true;
        }
        this.scrollTime = null;
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.Home {
  overflow: auto;
  height: calc(100%);
}
.viewContent {
  // height: calc(100vh - 280px);
  //padding-bottom: 48px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  background-color: $bg-color;
  margin-top: 238px;
  //position: relative;
  //height: calc(100vh - 120px);
}
.overflow {
  overflow: auto;
}
</style>
