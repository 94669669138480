<template>
  <div :class="['tabs', {'showTab': showTab, 'isVn': isVn}]">
    <div
      :class="['item', { active: active == 'Schedule' }]"
      @click="$router.push({ name: 'Schedule' })"
    >
      <img
        v-if="active == 'Schedule'"
        :src="require(`@/assets/imgs/comm/Schedule-active.svg`)"
        alt=""
      />
      <img v-else :src="require(`@/assets/imgs/comm/Schedule${
            theme == 'dark' ? '-active' : ''
          }.svg`)" alt="" />
      {{ $t("home.Schedule") }}
    </div>
    <div
      :class="['item', { active: active == 'Integral' }]"
      @click="$router.push({ name: 'Integral' })"
    >
      <img
        v-if="active == 'Integral'"
        :src="require(`@/assets/imgs/comm/Integral-active.svg`)"
        alt=""
      />
      <img
        v-else
        :src="
          require(`@/assets/imgs/comm/Integral${
            theme == 'dark' ? '-active' : ''
          }.svg`)
        "
        alt=""
      />
      {{ $t("home.Integral") }}
    </div>
    <div
      :class="['item', { active: active == 'Player' }]"
      @click="$router.push({ name: 'Player' })"
    >
      <img
        v-if="active == 'Player'"
        :src="require(`@/assets/imgs/comm/Player-active.svg`)"
        alt=""
      />
      <img
        v-else
        :src="
          require(`@/assets/imgs/comm/Player${
            theme == 'dark' ? '-active' : ''
          }.svg`)
        "
        alt=""
      />
      {{ $t("home.Player") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ['showTab'],
  name: "tabs",
  computed: {
    active() {
      return this.$route.name;
    },
    theme() {
      return this.common.theme;
    },
    isVn() {
      return this.$i18n.locale == "vn";
    },
    ...mapState(["common"]),
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  opacity: 0;
  transition: .4s;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 314px;
  // background: url(../../assets/imgs/comm/navBg.png) no-repeat;
  background-size: 100% 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  backdrop-filter: blur(5px);
  border-radius: 30px;
  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #131a3e;
    width: 98px;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    img {
      width: 18px;
      margin-right: 8px;
    }
  }
  &.isVn{ 
    .item{
      font-size: 10px;
    }
  }
  .active {
    background: #193ADD;
    color: #fff;
    border-radius: 30px;
  }
  .schedule {
    width: 56px;
    height: 57px;
    flex: none;
  }
  .schedule.active {
    background: none;
    color: #fff;
  }
  .scheduleBg {
    position: absolute;
    width: 56px;
    // top: -20px;
  }
  .wrap {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 7px;
      margin-bottom: 2px;
    }
  }
}
.showTab{
  opacity: 1;
}
</style>

<style lang="scss">
.dark{
  .tabs{
    background: rgba(37, 37, 37, 0.8);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  backdrop-filter: blur(5px);
  .item{
    color: #fff;
  }
  }
}
</style>